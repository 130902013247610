<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="8"
                sm="4"
                md="3"
                lg="2"
                xl="2"
                class="px-1"
              >
                <v-currency-field
                  v-model="companyRegistrationCost"
                  :label="$t('Company Registration Cost')"
                  dense
                  outlined
                  hide-details
                />
              </v-col>

              <v-col
                cols="4"
                class="px-1"
              >
                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="saveCompanyRegistrationCost()"
                >
                  {{ $t('Save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Account Statement -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col
                cols="auto"
                class="text-h6"
              >
                {{ $t('Accounting Actions') }}
              </v-col>

              <v-col cols="auto">
                <v-btn
                  depressed
                  small
                  :class="{
                    'black white--text': !$vuetify.theme.dark,
                    'white black--text': $vuetify.theme.dark,
                  }"
                  @click="$_print()"
                >
                  <v-icon left>
                    mdil-printer
                  </v-icon>

                  {{ $t('Print') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm
              >
                <v-row class="justify-sm-end">
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      small
                      depressed
                      @click="addDebit()"
                    >
                      {{ $t('Transaction Price') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      color="error"
                      small
                      depressed
                      @click="addExpense()"
                    >
                      {{ $t('Expense') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      color="green darken-2"
                      class="white--text"
                      small
                      depressed
                      @click="addReceipt()"
                    >
                      {{ $t('Add receipt') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-simple-table
                  id="print-area"
                  class="print-table"
                >
                  <template #default>
                    <thead>
                      <tr>
                        <invoice-header />
                      </tr>

                      <tr>
                        <th
                          colspan="100"
                          class="text-subtitle-1 d-none d-print-table-cell font-weight-bold black--text"
                          style="height: 30px"
                        >
                          <table style="width: 100%">
                            <tr>
                              <th class="text-start">
                                {{ $t('Company Name') }}: {{ company.name }}
                              </th>
                              <th class="text-end">
                                {{ $t('Manager') }}: {{ company.manager }}
                              </th>
                            </tr>
                          </table>
                        </th>
                      </tr>

                      <tr>
                        <th>#</th>
                        <th>{{ $t('Date/Time') }}</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('Invoice No') }}</th>
                        <th class="d-print-none">
                          {{ $t('Invoice Picture') }}
                        </th>
                        <th>{{ $t('Description') }}</th>
                        <th>{{ $t('Credit') }}</th>
                        <th>{{ $t('Debt') }}</th>
                        <th>{{ $t('Balance') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, accIndex) in apiData.data"
                        :key="accIndex"
                        class="pointer"
                        @click="updateRow(row)"
                      >
                        <td>{{ accIndex + 1 }}</td>
                        <td
                          class="dir-ltr"
                          :class="{
                            'text-end': $vuetify.rtl,
                            'text-start': !$vuetify.rtl,
                          }"
                        >
                          {{ $_date_format(row.datetime) }}
                        </td>
                        <td>
                          <v-chip
                            label
                            small
                            :color="getChipColor(row.action_type)"
                          >
                            {{ $t(row.action_type) }}
                          </v-chip>
                        </td>
                        <td>{{ row.invoice_no }}</td>
                        <td class="d-print-none">
                          <template v-if="row.attachments.length">
                            <div style="max-width: 150px">
                              <div class="d-flex flex-wrap">
                                <a
                                  v-for="(attachment, index) in row.attachments"
                                  :key="index"
                                  :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                                  target="_blank"
                                  class="me-2"
                                  @click.stop
                                >
                                  <v-icon>mdil-eye</v-icon>
                                </a>
                              </div>
                            </div>
                          </template>
                        </td>
                        <td>
                          <span
                            class="d-inline-block d-md-none text-truncate"
                            style="max-width: 200px;"
                          >
                            {{ row.description }}
                          </span>
                          <span class="d-none d-md-inline-block">
                            {{ row.description }}
                          </span>
                        </td>
                        <td>{{ $_number_format(row.credit) }}</td>
                        <td>{{ $_number_format(row.debit) }}</td>
                        <td>{{ $_number_format(row.balance) }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr
                        v-if="apiData.totals"
                        class="font-weight-bold grey"
                        :class="{
                          'darken-3': $vuetify.theme.dark,
                          'lighten-3': !$vuetify.theme.dark,
                        }"
                      >
                        <td colspan="5" />
                        <td class="d-print-none" />
                        <td>{{ $_number_format(apiData.totals.credit) }}</td>
                        <td>{{ $_number_format(apiData.totals.debit) }}</td>
                        <td>{{ $_number_format(apiData.totals.balance) }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Receipt FOR ME -->
      <!-- <v-col
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="3"
      >
        <v-card outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="9"
                class="px-1"
              >
                <v-currency-field
                  v-model="companyRegistrationCost"
                  :label="$t('Company Registration Cost')"
                  dense
                  outlined
                />
              </v-col>

              <v-col
                cols="3"
                class="px-1 text-end"
              >
                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="saveCompanyRegistrationCost()"
                >
                  {{ $t('Save') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="mb-6"
              >
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                class="px-1 mb-4"
              >
                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="addDebit()"
                >
                  {{ $t('Transaction Price') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                class="px-1 mb-4 text-end"
              >
                <v-btn
                  color="success"
                  small
                  depressed
                  @click="addCostReceipt()"
                >
                  {{ $t('Add receipt') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="px-1 text-subtitle-1"
              >
                {{ $t('Received amounts') }}
              </v-col>
            </v-row>

            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr
                    v-for="(receipt, rIndex) in forMeReceipts"
                    :key="rIndex"
                  >
                    <td>{{ $_number_format(receipt.amount) }}</td>
                    <td class="dir-ltr">
                      {{ $_date_time_format(receipt.action_date) }}
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        icon
                        depressed
                        @click="deleteReceipt(receipt)"
                      >
                        <v-icon>
                          mdil-trash
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <add-edit
      :form-data="formData"
      :add-edit-dialog="addEditDialog"
      :title="title"
      :url="url"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @saved="getInitData()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      company: {},
      apiData: {},
      debits: [],
      forMeReceipts: [],
      formData: {},
      editMode: false,
      addEditDialog: false,
      url: '',
      title: '',
      companyRegistrationCost: 0,
    }
  },

  watch: {
    company: {
      immediate: true,
      deep: true,
      handler() {
        this.companyRegistrationCost = this.company.registration_cost ?? 0
      }
    }
  },

  mounted() {
    this.getViewData()
    this.getInitData()
  },

  methods: {
    ...mapActions([
      'getExpenseTypeList'
    ]),

    async getViewData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.company = res.data
      })
    },

    getInitData() {
      this.getExpenseTypeList()
      this.getForMeReceipts()
      this.getAccStmtData()
    },

    getChipColor(type) {
      if (type == 'Expense') return 'error'
      if (type == 'Receipt') return 'success'
      if (type == 'Debit') return 'primary'
    },

    addCostReceipt() {
      this.editMode = false
      this.formData = {
        model_name: 'company',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        type: 1,
        attachments: [],
      }
      this.title = this.$t('Receive Registration Cost')
      this.url = 'receipt/'
      this.addEditDialog = true
    },

    addDebit() {
      this.editMode = false
      this.formData = {
        model_name: 'company',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        attachments: [],
      }
      this.title = this.$t('Add Transaction Price')
      this.url = 'debit/'
      this.addEditDialog = true
    },

    addReceipt() {
      this.editMode = false
      this.formData = {
        model_name: 'company',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        type: 2,
        attachments: [],
      }
      this.title = this.$t('Add receipt')
      this.url = 'receipt/'
      this.addEditDialog = true
    },

    addExpense() {
      this.editMode = false
      this.formData = {
        model_name: 'company',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        attachments: [],
      }
      this.title = this.$t('Add expense')
      this.url = 'expense/'
      this.addEditDialog = true
    },

    async updateRow(row) {
      this.formData = { ...row }

      if (row.action_type == 'Expense') {
        this.title = this.$t('Update expense')
        this.url = `expense/${row.id}/`
        this.formData.expense_type_id = { text: row.expense_type.name, value: row.expense_type.id }
      }
      if (row.action_type == 'Receipt') {
        this.title = this.$t('Update receipt')
        this.url = `receipt/${row.id}/`
      }
      if (row.action_type == 'Debit') {
        this.title = this.$t('Update debit')
        this.url = `debit/${row.id}/`
      }

      if (this.formData.attachments.length) {
        await this.formData.attachments.forEach(attach => {
          if (!attach.attachment.includes(this.$_file_path())) {
            attach.attachment = `${this.$_file_path()}${attach.attachment}?token=${this.$_auth_token()}`
          }
        })
      }
      this.editMode = true
      this.addEditDialog = true
    },

    saveCompanyRegistrationCost() {
      if (!this.companyRegistrationCost) {
        return this.$_notify(this.$t('Please set registration cost'), 'error')
      }

      let form = { registration_cost: this.companyRegistrationCost }
      axios.put(`company/${this.$route.params.id}/save-registration-cost/`, form).then(() => {
        this.$_notify(this.$t('Saved successfully'))
      })
    },

    getForMeReceipts() {
      let form = {
        model_name: 'company',
        model_id: this.$route.params.id,
      }

      axios.post('receipt/for-me-receipts/', form).then(res => {
        this.forMeReceipts = res.data
      })
    },

    getAccStmtData() {
      let form = {
        model_name: 'company',
        model_id: this.$route.params.id,
      }
      axios.post('report/company-account-statement', form).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>